<template>
  <div class="single-props-container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Wert</th>
          <th scope="col">Beschreibung</th>
        </tr>
      </thead>
     
      <tbody class="" v-for="item in itemProps">
        <tr v-for="props in item.properties" v-if="!propsIdsArray.includes(props.propertyId)" >
          <td>{{props.name}}</td>
          <td v-if="typeof props.value !== undefined && props.value !== null && props.value.includes('https')"><a target="_blank" :href="props.value">Datei öffnen</a></td>
          <td v-else>{{props.value}}</td>
          <td>{{props.description}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    propsIds: String,
  },
  inject: {
        itemId: {
            default: null
        }
  },
  data() {
    return {
      itemProps: null,
      propsIdsArray: [],
    }
  },
  computed: {
    SingleItemId () {
      let itemsIds = [];
      itemsIds.push(this.$store.getters[`${this.itemId}/currentItemVariation`].variation.id);
      return itemsIds;
    }
  },
  methods: {
    
    postProps() {
      // console.log(this.SingleItemId);
      
      axios.post('/rest/getsingleprops/1', {
        items: this.SingleItemId
      })
      .then((response) => {
        console.log(response.data);
        this.itemProps = response.data
      }, (error) => {
        console.log(error);
      });
    }

  },

  mounted () {
     
    this.postProps();
    this.propsIdsArray = this.propsIds.split(',');
    this.propsIdsArray = this.propsIdsArray.map(function (x) {
          // console.log(parseInt(x, 10));
          return parseInt(x, 10); 
    }); 
  }
}
</script>